import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl']
})
export class LoginComponent implements OnInit {

  showSignUpModal = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  closeSignUpModal(event: any): void {
    console.log('toggleModel')
    this.toggleModel();
  }

  toggleModel(): void {
    this.showSignUpModal = !this.showSignUpModal;
  }
}
