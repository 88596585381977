import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.styl']
})
export class AppLayoutComponent implements OnInit {
  year: number = (new Date().getFullYear());

  constructor() {
  }

  ngOnInit(): void {
  }

}
