<section class="intro p-5 lg:p-0">
  <div class="container lg:mx-auto py-2">
    <div class="w-full lg:w-2/3 xl:w-2/4">
      <h1 class="mt-4 mb-3 lg:mt-16 lg:mb-7">
        Safe, ethical software
        for recovering lost cryptocurrencies
      </h1>
      <a href="//wallet.scanhash.com" class="inline-block rounded-full text-center py-2 px-5 xl:py-3 xl:px-10 focus:outline-none launch">
        Launch ScanHash
      </a>
    </div>
  </div>
</section>

<section class="about">
  <div class="mx-auto lg:flex">
    <div class="w-full lg:w-2/4 bg">
      <img src="/assets/img/people-watching-crypto.jpg" alt="Watching Crypto">
    </div>
    <div class="w-full lg:w-2/4 p-8 lg:p-16 xl:p-28 2xl:32 detail">
      <h2 class="mt-0">About ScanHash</h2>
      <p>
        With the click of a button and the wallet owner’s authorization, ScanHashsearches, analyzes, and integrates with
        customers' technology systems to search for clues and traces of their private key, digital wallets and other
        crypto-enabling logs and records.
      </p>
      <p>
        ScanHash makes recovering lost cryptocurrency affordable, accessible, and safe.
      </p>
      <p>
        Try ScanHash today for free; we only charge if we successfully recover your cryptocurrency.
      </p>

      <a routerLink="/login">Launch ScanHash <i class="fas fa-chevron-circle-right"></i></a>

    </div>
  </div>
</section>

<section class="still">
  <div class="mx-auto lg:flex">
    <div class="w-full lg:w-2/4 p-8 lg:p-16 xl:p-28 2xl:32">
      <h3>Still Can't Find Your Crypto?</h3>
      <p>
        Every lost cryptocurrency story is different. If ScanHash’s software doesn’t find your lost crypto currency, let
        us know. We’re always working to improve our product and want to hear from you!
      </p>
      <p>
        Please fill out our contact form to share your story or submit a question.
      </p>
    </div>
    <div class="w-full lg:w-2/4 p-8 lg:p-16 xl:p-28 2xl:32">
      <form>
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="first_name">
            First Name
          </label>
          <input
            class="appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="first_name" type="text" placeholder="enter your first name">
        </div>
        <div class="mb-6">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="last_name">
            Last Name
          </label>
          <input
            class="appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="last_name" type="text" placeholder="enter your last name">
        </div>
        <div class="mb-6">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
            Email Address
          </label>
          <input
            class="appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email" type="text" placeholder="enter your email address">
        </div>
        <div class="mb-6">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="message">
            Message
          </label>
          <textarea
            class="appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="message" placeholder="How can we help you?" rows="5"></textarea>
        </div>
        <div class="flex items-center justify-between">
          <button
            class="rounded-full w-full lg:w-1/3 xl:w-1/4 py-1 xl:py-1 px-1 xl:px-3 focus:outline-none focus:shadow-outline"
            type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
