import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';

@Component({
  selector: 'app-modal-small',
  templateUrl: './modal-small.component.html',
  styleUrls: ['./modal-small.component.styl']
})
export class ModalSmallComponent implements OnInit {

  @Input()
  title: string = '';

  @Input()
  html: string = '';

  @Output()
  onClose = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  closeModal(): void {
    console.log('close')
    this.onClose.emit(false);
  }

}
